import type { FieldInclude } from './base';
import type { StandardFieldText } from './standard';

export type FieldTextValue = string;

export type FieldTextValueInput = string | FieldTextValue;

/**
 * Convert field text value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldTextValue(options: {
  /** Accepts `TaxonomyFieldText` */
  field: { type: 'text'; parameters: { include?: Partial<FieldInclude> } };
  input: FieldTextValueInput | undefined;
}): string {
  const { field, input } = options;

  return asTextValue(input, field.parameters.include ?? {}) ?? '';
}

/**
 * Convert field text value input to the value
 * structured output object.
 */
export function decodeFieldTextValue(options: {
  /** Accepts `TaxonomyFieldText` */
  field: { type: 'text'; parameters: { include?: Partial<FieldInclude> } };
  input: FieldTextValueInput | undefined;
}): FieldTextValue | undefined {
  const { field, input } = options;

  return asTextValue(input, field.parameters.include ?? {});
}

function asTextValue(
  value: FieldTextValueInput | undefined,
  includes: Partial<FieldInclude>,
) {
  if (value === undefined) return undefined;

  if (includes.uppercase !== false && includes.lowercase === false) {
    return value.toUpperCase();
  } else if (includes.uppercase === false && includes.lowercase !== false) {
    return value.toLowerCase();
  }

  return value;
}

export function generateFieldTextValue(options: {
  /** Accepts `TaxonomyFieldText` */
  field: { type: 'text'; parameters: Partial<StandardFieldText> };
}) {
  const { parameters } = options.field;

  let char = '';

  if (!parameters.include || parameters.include.digits !== false) {
    char += '0123456789';
  }
  if (!parameters.include || parameters.include.uppercase !== false) {
    char += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  if (!parameters.include || parameters.include.lowercase !== false) {
    char += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (parameters.include?.spaces) {
    char += ' ';
  }
  if (parameters.include?.characters) {
    char += parameters.include.characters;
  }

  let value = '';
  const max =
    (parameters?.max ?? 3) -
    (parameters?.prefix?.length ?? 0) -
    (parameters?.suffix?.length ?? 0);

  for (let i = 0; i < max; i += 1) {
    value += char.charAt(Math.floor(Math.random() * char.length));
  }

  return `${parameters?.prefix ?? ''}${value}${parameters?.suffix ?? ''}`;
}
