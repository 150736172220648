import type {
  AdContentApiModel,
  BaseGuideline,
  BillingCodeTemplateApiModel,
  BillingExternalCodeTemplateApiModel,
  ClickTagsTemplateApiModel,
  ExternalCodeTemplateApiModel,
  LabelTemplateApiModel,
  NamingConventionApiModel,
  PurchaseOrderApiModel,
  TrackingTemplateApiModel,
  UrlParametersTemplateApiModel,
  UrlTemplateApiModel,
  ViewTagsTemplateApiModel,
} from '../guidelines';

export default (
  model: BaseGuideline,
): model is
  | NamingConventionApiModel
  | TrackingTemplateApiModel
  | UrlTemplateApiModel
  | UrlParametersTemplateApiModel
  | LabelTemplateApiModel
  | ClickTagsTemplateApiModel
  | PurchaseOrderApiModel
  | ViewTagsTemplateApiModel
  | ExternalCodeTemplateApiModel
  | BillingExternalCodeTemplateApiModel
  | BillingCodeTemplateApiModel
  | AdContentApiModel =>
  [
    'TrackingTemplate',
    'NamingConvention',
    'UrlTemplate',
    'UrlParametersTemplate',
    'LabelTemplate',
    'PurchaseOrder',
    'ViewTagsTemplate',
    'ClickTagsTemplate',
    'ExternalCodeTemplate',
    'BillingExternalCodeTemplate',
    'BillingCodeTemplate',
    'AdContent',
  ].includes(model.type);
