import type { StandardFieldNumber } from './standard';

export type FieldNumberValue = number;

export type FieldNumberValueInput = string | FieldNumberValue;

/**
 * Convert field number value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldNumberValue(options: {
  /** Accepts `TaxonomyFieldNumber` */
  field: { type: 'number' };
  input: FieldNumberValueInput | undefined;
}): string {
  const { input } = options;

  const number = asNumber(input);
  if (number === undefined) return '';

  // returning original input value preserves leading zeros in numbers
  return input?.toString() ?? '';
}

/**
 * Convert field number value input to the value
 * structured output object.
 */
export function decodeFieldNumberValue(options: {
  /** Accepts `TaxonomyFieldNumber` */
  field: { type: 'number' };
  input: FieldNumberValueInput | undefined;
}): FieldNumberValue | undefined {
  const { input } = options;

  return asNumber(input);
}

function asNumber(
  input: FieldNumberValueInput | undefined,
): number | undefined {
  if (input === undefined) return undefined;
  else if (typeof input === 'string') {
    const value = parseFloat(input);

    return isNaN(value) ? undefined : value;
  } else if (typeof input === 'number') {
    return input;
  }

  return undefined;
}

export function generateFieldNumberValue(options: {
  /** Accepts `TaxonomyFieldNumber` */
  field: { type: 'number'; parameters: Partial<StandardFieldNumber> };
}) {
  const { parameters } = options.field;

  let char = '0123456789';

  let value = '';
  const max =
    (parameters?.max ?? 3) -
    (parameters.prefix?.length || 0) -
    (parameters.suffix?.length || 0);

  for (let i = 0; i < max; i += 1) {
    value += char.charAt(Math.floor(Math.random() * char.length));
  }

  return `${parameters.prefix ?? ''}${value}${parameters.suffix ?? ''}`;
}
