import {
  type AnyFieldValueInput,
  type TaxonomiesFieldsPivot,
  formatAnyFieldValue,
} from '../fields';

/**
 * From a list of fields and associated values, build the output value.
 *
 * Note that this function doesn't resolve field/templates dependencies.
 * The list of fields should be exhaustive.
 */
export function useTemplateOutput(options: {
  fields: TaxonomiesFieldsPivot[];
  values: Record<string, AnyFieldValueInput | undefined>;
  useDependencyOutput?: (templateId: string) => string | undefined;
}) {
  return (
    options.fields
      .sort((a, b) => a.position - b.position)
      // eslint-disable-next-line complexity
      .map(({ field }) => {
        if (field.type === 'fixed') {
          return field.parameters.value;
        } else if (field.type === 'template') {
          const value = options.values[field.id];

          if (typeof value === 'string' && value) return value;

          return (
            options.useDependencyOutput?.(field.templateId) ??
            options.values[field.templateId]
          );
        }

        const value = formatAnyFieldValue(field, options.values[field.id]);

        if (
          field.parameters.defaultValue &&
          (value === undefined || value === '')
        ) {
          return field.parameters.defaultValue;
        }

        if (
          typeof value === 'string' &&
          field.parameters.transformValue === 'encodeURIComponent'
        ) {
          return encodeURIComponent(value);
        }

        if (field.parameters.transformValue === 'removeDots') {
          return value?.replaceAll('.', '_');
        }

        if (
          field.type === 'url' &&
          !value?.endsWith('?') &&
          !value?.endsWith('&')
        ) {
          if (value?.includes('?')) {
            return `${value}&`;
          }

          return `${value}?`;
        }

        return value;
      })
      .join('')
  );
}
